import { css } from '@emotion/react';
import { ModalWindow, Button } from 'bondsports-utils';
import { useColors } from 'hooks/useColors';
import { IColors } from 'types/colors';
import { i18n } from 'lib/i18n';
import { errorMessageStore } from 'stores/errorMessageStore';
import { useRecoilState, useRecoilValue } from 'recoil';
import IconError from 'components/shared/Icons/IconError';

const informationModalCss = css`
	padding: 38px 90px 32px 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	line-height: 1.8rem;
`;
const buttonCss = css`
	margin-top: 38px;
`;

const infoCss = (colors: IColors) => css`
	color: ${colors.bg_text_secondary};
	margin: 0;
	font-size: 1.4rem;
`;
const titleCss = css`
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 8px;
`;

function ErrorMessageModal() {
	const { colors } = useColors();
	const title = useRecoilValue(errorMessageStore.title);
	const erroressage = useRecoilValue(errorMessageStore.erroressage);
	const [isShowing, setIsShowing] = useRecoilState(errorMessageStore.isShowing);

	const toggle = () => setIsShowing(!isShowing);

	return (
		<ModalWindow isShowing={isShowing} toggle={toggle} padding={0}>
			<div data-aid="ErrorMessageModal" css={informationModalCss}>
				<IconError />
				<h2  data-testid="ErrorMessageModal-6826D1" css={titleCss}>{title}</h2>
				<p css={infoCss(colors)}>{erroressage}</p>

				<Button data-aid="button-ErrorMessageModal-thanks" css={buttonCss} sizer="M" theme="primary" onClick={toggle}>
					{i18n.buttons.thanks}
				</Button>
			</div>
		</ModalWindow>
	);
}
export default ErrorMessageModal;
