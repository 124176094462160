import { atom } from 'recoil';

const title = atom<string>({
	key: 'title',
	default: '',
});

const erroressage = atom<string>({
	key: 'erroressage',
	default: '',
});

const isShowing = atom<boolean>({
	key: 'isShowing',
	default: false,
});

export const errorMessageStore = { title, erroressage, isShowing };
