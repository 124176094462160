import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { AppBar, Container, Grid, Toolbar } from '@material-ui/core';

import { i18n } from 'lib/i18n';

import { userStore } from 'stores/userStore';
import { IColors } from 'types/colors';
import Button from 'components/shared/Button/Button';
// import BrandLogo from 'components/shared/BrandLogo/BrandLogo';
// import Link from 'components/shared/Link/Link';

import UserMenu from 'components/shared/Navbar/UserMenu';
import { useColors } from 'hooks/useColors';

const toolbarCss = css`
	&.MuiToolbar-gutters {
		padding-left: 0;
		padding-right: 0;
	}
`;

const signupBtnCss = (colors: IColors) => css`
	&.MuiButtonBase-root {
		color: ${colors.tx_text_primary};
		margin-left: 8px;
	}
`;

function navigateToLogin()
{
	if (!process.env.NEXT_PUBLIC_LOGIN_REDIRECT_URL) {
		console.error("Login URL is undefined");
		return;
	}
	console.info("Redirect URL");
	console.info(process.env.NEXT_PUBLIC_LOGIN_REDIRECT_URL);

	const loginUrl = new URL(process.env.NEXT_PUBLIC_LOGIN_REDIRECT_URL);
	loginUrl.searchParams.set('state', window.location.toString());

	window.location.assign(loginUrl.toString());
}

function navigateToSignup()
{
	if (!process.env.NEXT_PUBLIC_SIGNUP_REDIRECT_URL) {
		console.error("Signup URL is undefined");
		return;
	}

	const signupUrl = new URL(process.env.NEXT_PUBLIC_SIGNUP_REDIRECT_URL);
	signupUrl.searchParams.set('state', window.location.toString());

	window.location.assign(signupUrl.toString());
}

function Navbar() {
	const loginSettings = userStore.useInitLoginSettings();
	const userSettings = userStore.useInitUserSettings();
	const [showUserMenu, setShowUserMenu] = useState(false);
	const { colors } = useColors();

	const renderLoginSignup = () => {
		return (
			<div>
				<Button
					data-aid="button-Navbar-tab1"
					variant="text"
					onClick={() => {
						navigateToLogin();
					}}
				>
					{i18n.auth.loginBtn}
				</Button>
				<Button
					data-aid="button-Navbar-tab2"
					css={signupBtnCss(colors)}
					variant="contained"
					color="secondary"
					onClick={() => {
						navigateToSignup();
					}}
				>
					{i18n.auth.signupBtn}
				</Button>
			</div>
		);
	};

	useEffect(() => {
		if (loginSettings?.isLogin) {
			setShowUserMenu(true);
		}
	}, [userSettings, loginSettings]);

	return (
		<AppBar position="static">
			<Toolbar css={toolbarCss} variant="dense">
				<Container maxWidth="lg">
					<Grid container justify="flex-end">
						{/* <div>
              <Link href="/" aria-label="Bond Sports logo">
                <BrandLogo />
              </Link>
           </div>*/}

						{loginSettings?.isLogin && showUserMenu ? <UserMenu showMenu={setShowUserMenu} /> : renderLoginSignup()}
					</Grid>
				</Container>
			</Toolbar>
		</AppBar>
	);
}

export default Navbar;
