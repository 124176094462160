const IconError = () => (
	<svg  data-testid="IconError-8A9D8F" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			// eslint-disable-next-line max-len
			d="M11.7104 10.0001L18.979 2.73133C19.452 2.25853 19.452 1.49407 18.979 1.02126C18.5062 0.548455 17.7417 0.548455 17.2689 1.02126L10.0001 8.29006L2.73153 1.02126C2.2585 0.548455 1.49426 0.548455 1.02146 1.02126C0.54843 1.49407 0.54843 2.25853 1.02146 2.73133L8.29003 10.0001L1.02146 17.2689C0.54843 17.7417 0.54843 18.5062 1.02146 18.979C1.25708 19.2148 1.5669 19.3333 1.87649 19.3333C2.18609 19.3333 2.49568 19.2148 2.73153 18.979L10.0001 11.7102L17.2689 18.979C17.5047 19.2148 17.8143 19.3333 18.1239 19.3333C18.4335 19.3333 18.7431 19.2148 18.979 18.979C19.452 18.5062 19.452 17.7417 18.979 17.2689L11.7104 10.0001Z"
			fill="#E02020"
		/>
	</svg>
);

export default IconError;
