import { css } from '@emotion/react';
import { IColors } from 'types/colors';
import { useColors } from 'hooks/useColors';

import { Box, Hidden, useMediaQuery } from '@material-ui/core';

import Navbar from 'components/shared/Navbar/Navbar';
import Picture from 'components/shared/Picture/Picture';
import { zLayers } from 'styles/theme';
import { mobileOnly } from 'styles/utils';
import ErrorMessageModal from 'components/shared/Dialog/ErrorMessageModal';

const pageLayoutCss = css`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	position: relative;
	margin-bottom: 50px;
`;

const overlayCss = (colors: IColors) => css`
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 500px;
	opacity: 0.75;
	background-image: linear-gradient(to bottom, ${colors.bg_text_primary}, ${colors.bg_text_primary});
`;

const mainCss = (bottomPadding: number) => css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	position: relative;
	padding-bottom: ${bottomPadding}px;
`;

const heroBgCss = css`
	max-height: 500px;
	object-fit: cover;
	object-position: center center;
	position: absolute;
	min-height: 500px;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: ${zLayers.behindAll};
`;

interface Props {
	children: React.ReactNode;
	className?: string;
	/** Can override the default bottom-padding of the main container */
	bottomPadding?: number;
	showHeroBg?: boolean;
	heroBgImg?: string;
}

/**
 * A wrapper around common pages that use the header and footer.
 *
 * Features:
 * - Renders NavBar and Footer.
 * - Covers the whole available height of the page (sticks footer to bottom).
 * - Stretches the main content to fill the available space.
 * - Adds configurable bottom padding to the main content.
 */
function Page({ children, className, bottomPadding = 0, showHeroBg = false, heroBgImg }: Props) {
	const isMobile = useMediaQuery(mobileOnly);
	const { colors } = useColors();

	return (
		<div css={pageLayoutCss}>
			<Navbar />
			<Hidden smDown implementation="js">
				{showHeroBg ? (
					<>
						{!isMobile ? <Box css={overlayCss(colors)} /> : null}
						<Picture
							css={heroBgCss}
							isModule={!heroBgImg}
							path={!heroBgImg ? 'hero-bg.jpg' : heroBgImg}
							alt={!heroBgImg ? 'Sports field' : 'Facility main image'}
						/>
					</>
				) : null}
			</Hidden>
			<main css={mainCss(bottomPadding)} className={className}>
				{children}
			</main>
			<ErrorMessageModal />
		</div>
	);
}

export default Page;
